export class MapObject {
  constructor(element) {
    this.settings = Object.assign({
      lat: 0,
      lng: 0,
      category: 'shopping',
      name: '',
      color: '%238EBA3E',
    }, element.dataset);
    this.placemark = undefined;
  }

  getLat() {
    return parseFloat(this.settings.lat);
  }

  getLng() {
    return parseFloat(this.settings.lng);
  }

  getCategory() {
    return this.settings.category;
  }

  getName() {
    return this.settings.name;
  }

  getColor() {
    return this.settings.color;
  }

  setPlacemark(placemark) {
    this.placemark = placemark;
  }

  getPlacemark() {
    return this.placemark;
  }
}